<template>
  <div>
    <Pane />
    <div class="container">
      <div class="content">
        <a-form
          layout="horizontal"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          :colon="false"
        >
          <a-form-item label="账号">
            <a-input disabled :value="detail.code" />
          </a-form-item>
          <a-form-item label="公司名称">
            <a-input disabled :value="detail.company" />
          </a-form-item>
          <a-form-item label="联系人">
            <a-input disabled :value="detail.contact" />
          </a-form-item>
          <a-form-item label="手机号">
            <a-input disabled :value="detail.mobile" />
          </a-form-item>
          <a-form-item label="营业执照">
            <a-input disabled :value="detail.licenseCode" />
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 20, offset: 4 }">
            <div>
              <div class="license">
                <img src="attachments" alt="营业执照" />
              </div>
              <div class="center image-info">
                <span>营业执照</span>
                <a href="attachments" download target="_blank" class="download">
                  <a-icon type="download" />
                </a>
              </div>
            </div>
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 20, offset: 4 }">
            <a-space>
              <a-button type="danger" @click="rejectVisible = true"
                >拒绝</a-button
              >
              <a-button type="primary" @click="pass">通过</a-button>
            </a-space>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <a-modal
      title="拒绝原因"
      :visible="rejectVisible"
      @cancel="rejectVisible = false"
      @ok="ok"
    >
      <a-textarea placeholder="请输入拒绝原因" v-model="rejectReason" />
    </a-modal>
  </div>
</template>


<script>
import { audit } from "@/api/bidding/company";
export default {
  data() {
    return {
      detail: {},

      rejectVisible: false,
      rejectReason: "",
    };
  },

  mounted() {
    const rawStr = window.localStorage.getItem("bidding-audit-company");
    if (rawStr) {
      this.detail = Object.freeze(JSON.parse(rawStr));
    }

    const { id } = this.$route.query;
   if(id){
    this.getDetail(id);
   }
  },

  methods: {
    getDetail(){
      /* fetchDetail(id).then(res => {
        console.log(res)
        
      }) */
    },
    pass() {
      const that = this;
      this.$confirm({
        title: "是否通过？",
        onOk() {
          audit({
            id: that.detail.id,
            status: "first_approved",
          }).then(() => {
            that.$router.go(-1);
          });
        },
      });
    },
    ok() {
      if (!this.rejectReason) {
        this.$message.error("请填写拒绝原因");
        return;
      }
      audit({
        id: this.detail.id,
        status: "rejected",
        auditOpinion: this.rejectReason,
      }).then(() => {
        this.rejectVisible = false;
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}
.content {
  width: 480px;
}
.license {
  width: 224px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  margin-bottom: 10px;
}
.license > img {
  width: 100%;
}
.image-info {
  width: 224px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.image-info > .download {
  margin-left: 9px;
}
</style>